<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title >
        <span class="headline primary--text">a</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-list three-line>              
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>AAA</v-list-item-title>

                    <v-list-item-subtitle>{{conteudo}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">     
              <editor v-model="conteudo"></editor>
            </v-col>                          
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>  
</template>

<script>
  import Editor from "@/components/comum/Editor";
  
  export default {
    name: 'relatorio-consulta',
    data() {
      return {
        conteudo: '<p>A Vue.js wrapper component for tiptap to use <code>v-model</code>.</p>',      
        conteudo2: '<p>A Vue.js wrapper component for tiptap to use <code>v-model</code>.</p>',
      }
    },
    components: {
      Editor
    },
  }
</script>
